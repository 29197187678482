<template>
  <div>
    <h1 class="heading-txt mb-3">Update Social Account</h1>
    <div class="row">
      <div class="col-md-5">
        <form role="form" @submit.prevent="updateSocialAccount">
          <div class="card siteCard mt-3">
            <div class="p-3">
              <div class="form-group">
                <label class="text-dark">Twitter</label>
                <input
                  v-model="platform.twitter"
                  required
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label class="text-dark">Instagram</label>
                <input
                  v-model="platform.instagram"
                  required
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label class="text-dark">Facebook</label>
                <input
                  v-model="platform.facebook"
                  required
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label class="text-dark">Soundcloud</label>
                <input
                  v-model="platform.soundcloud"
                  required
                  class="form-control"
                />
              </div>

              <div class="form-group">
                <label class="text-dark">Youtube</label>
                <input
                  v-model="platform.youtube"
                  required
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label class="text-dark">Tiktok</label>
                <input
                  v-model="platform.tiktok"
                  required
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label class="text-dark">Triller</label>
                <input
                  v-model="platform.triller"
                  required
                  class="form-control"
                />
              </div>

              <button
                type="submit"
                :disabled="loading"
                class="btn btn-primary btn-site px-5 mt-4"
              >
                <span> Save Profile</span>
                <BtnLoading v-if="loading" class="d-inline-block" height="18" />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import BtnLoading from "@/components/BtnLoading.vue";
export default {
  data() {
    return {
      loading: false,
      platform: {
        twitter: "tw",
        instagram: "ig",
        soundcloud: "sd",
        facebook: "fb",
        youtube: "yb",
        tiktok: "tk",
        triller: "tr",
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.user || {};
    },
  },
  components: {
    BtnLoading,
  },

  mounted() {
    this.addDefaultField();
  },

  methods: {
    addDefaultField() {
      for (let i = 0; i < this.user.socials.length; i++) {
        if (this.user.socials[i].platform == "twitter") {
          this.platform.twitter = this.user.socials[i].link;
        } else if (this.user.socials[i].platform == "instagram") {
          this.platform.instagram = this.user.socials[i].link;
        } else if (this.user.socials[i].platform == "facebook") {
          this.platform.facebook = this.user.socials[i].link;
        } else if (this.user.socials[i].platform == "soundcloud") {
          this.platform.soundcloud = this.user.socials[i].link;
        } else if (this.user.socials[i].platform == "youtube") {
          this.platform.youtube = this.user.socials[i].link;
        } else if (this.user.socials[i].platform == "tiktok") {
          this.platform.tiktok = this.user.socials[i].link;
        } else if (this.user.socials[i].platform == "triller") {
          this.platform.triller = this.user.socials[i].link;
        }
      }
    },
    updateSocialAccount() {
      this.loading = true;

      const platforms = [];
      for (const key in this.platform) {
        if (this.platform[key]) {
          platforms.push({
            platform: key,
            link: this.platform[key],
          });
        }
      }

      const data = {
        links: platforms,
      };

      let fetch = {
        path: `/profile/update/socials`,
        data,
      };

      this.$store
        .dispatch("postRequest", fetch)
        .then((resp) => {
          const { data } = resp.data;

          this.loading = false;
          this.resetUser();

          this.$toast.success(
            "Socials",
            "Your socials has been updated successfully",
            this.$toastPosition
          );
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            this.$toast.info(
              "Socials",
              err.response.data.message,
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Socials",
              "Unable to update socials, check the details and try again",
              this.$toastPosition
            );
          }
        });
    },
    resetUser() {
      let fetch = {
        path: `/profile`,
      };

      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          let { user } = resp.data.data;
          user = this.CryptoJS.AES.encrypt(
            JSON.stringify(user),
            this.$passPhrase
          ).toString();

          localStorage.setItem("user", user);
        })
        .catch((err) => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.heading-txt {
  font-size: 3em;
  line-height: 1;
  font-weight: 700;
  color: #000;
}
.sect-data {
  border-bottom: 1px solid gainsboro;
  margin-bottom: 13px;
}
.active-plan {
  color: #000;
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: 2px;
}
</style>
